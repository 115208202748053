import React from "react";
import PropTypes from "prop-types";
import Image from "../Global/Image";
import Link from "./Link";

// ====

const PlatformLinks = (props) => {
  const { platforms, link, size } = props;

  return (
    <>
      {platforms && (
        <ul className="list-unstyled m-0">
          {platforms.map((item) => {
            return (
              <>
                {item && item.icon.content && (
                  <li className="list-inline-item position-relative mx-2">
                    <Image
                      src={item.icon.content.image?.filename}
                      alt={item.platform}
                      sm={size ? size : "20x20"}
                      md={size ? size : "20x20"}
                      lg={size ? size : "25x25"}
                    />
                    {link === true && <Link to={item.link} stretched />}
                  </li>
                )}
              </>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default PlatformLinks;

// ====

PlatformLinks.propTypes = {
  items: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
PlatformLinks.defaultProps = {
  items: null,
};
