import React from "react";
import PropTypes from "prop-types";
import SmartTag from "../../utils/TagHandler";
import Link from "../Global/Link";
import PlatformLinks from "../Global/PlatformLinks";
import Image from "../Global/Image";
import Background from "../Global/Background";

const CardGame = (props) => {
  const { item, sm, md, lg, size } = props;

  return (
    <>
      {item && item.content ? (
        <Background
          src={
            item.content.background_1x1?.filename ||
            item.content.icon?.filename ||
            item.content.image?.filename ||
            null
          }
          sm={sm}
          md={md}
          lg={lg}
          className={`brand-radius brand-background-black ratio ratio-${size} brand-game-link`}
        >
          <div className="d-flex flex-column justify-content-center">
            {item.content.logo?.filename && (
              <Image
                src={item.content.logo?.filename}
                sm={sm}
                md={md}
                lg={lg}
                alt={item.title && item.title}
                className="w-100 brand-game-logo"
              />
            )}
          </div>
          <div className="d-block position-relative">
            <div className="d-flex flex-column p-2 brand-background-white-90 text-center position-absolute bottom-0 w-100 brand-game-info">
              <div className="mb-3 small">
                {item.content.lifecycle && (
                  <span className="brand-text-pink fw-bold">
                    {SmartTag(item.content.lifecycle)}
                  </span>
                )}
                {item.content.lifecycle && item.content.type && (
                  <span className="mx-2 brand-text-black-50">|</span>
                )}
                {item.content.type && (
                  <span className="fw-bold">{item.content.type}</span>
                )}
              </div>
              {item.content.title && (
                <h5 className="fw-bold mb-3">{item.content.title}</h5>
              )}
              <PlatformLinks platforms={item.content.platforms} />
            </div>
          </div>
          <Link to={item.full_slug} stretched />
        </Background>
      ) : (
        <div className="brand-radius brand-background-black brand-text-white w-100 h-100 d-flex justify-content-center align-items-center">
          Asset not set
        </div>
      )}
    </>
  );
};

export default CardGame;

CardGame.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
};

CardGame.defaultProps = {
  item: null,
  size: "1x1",
};

/*

style={{
        backgroundImage: `url(${SmartImage(
          item.content.background && item.content.background.filename,
          "600x600"
        )})`,
      }}

      {item.story && (
        <>
          {item.story.content.title && <h4>{item.story.content.title}</h4>}
          {item.story.content.preview && <p>{item.story.content.preview}</p>}
          {item.story.full_slug && <p>LINK: {item.story.full_slug}</p>}
          <Link to={item.story.full_slug}>LINK</Link>
        </>
      )}


            {item.content.logo && (
        <Image
          src={item.content.logo ? item.content.logo.filename : ""}
          sm={sm}
          md={md}
          lg={lg}
          alt={item.title}
          className="w-100 brand-animated brand-animated-logo"
        />
      )}

*/
