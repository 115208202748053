import React from "react";
import PropTypes from "prop-types";
import SmartImage from "../../utils/ImageHandler";

const Background = (props) => {
  const { src, sm, md, lg, alt, className, children } = props;

  let small,
    medium,
    large = md || lg || sm;

  return (
    <div
      className={`brand-background-responsive ${className && className}`}
      style={{
        "--bgSm": `url(${SmartImage(src && src, small)})`,
        "--bgMd": `url(${SmartImage(src && src, medium)})`,
        "--bgLg": `url(${SmartImage(src && src, large)})`,
      }}
    >
      {children}
    </div>
  );
};

export default Background;

Background.propTypes = {
  src: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Background.defaultProps = {
  src: "",
  sm: "1920x1080",
  md: "1920x1080",
  lg: "1920x1080",
  alt: "",
  className: "",
};

/*
      <picture>
        {sm && (
          <source
            srcset={SmartImage(
              "https://a.storyblok.com/f/132233/1280x720/c22f3714c2/background-monster-harvest.jpeg",
              sm
            )}
            media="(max-width: 767.98px)"
          />
        )}
        {md && (
          <source
            srcset={SmartImage(
              "https://a.storyblok.com/f/132233/600x337/c45fa0f533/background-foreclosed.jpeg",
              md
            )}
            media="(max-width: 991.98px)"
          />
        )}
        {lg && (
          <source
            srcset={SmartImage(
              "https://a.storyblok.com/f/132233/1920x1080/c24eac7796/background-alex-kidd.jpeg",
              lg
            )}
          />
        )}
        <img
          src={SmartImage(
            "https://a.storyblok.com/f/132233/1920x1080/6920f5c81c/404.jpg"
          )}
          alt={alt}
          className={className}
        />
      </picture>
*/
